import { getCookie, removeCookie, setCookie } from '@mnd-frontend/utils';
import { parse, stringify } from 'qs';
import { useEffect } from 'react';

const isOverridesEqual = (a: Record<string, boolean>, b: Record<string, boolean>) => {
  const aEntries = Object.entries(a);
  const bEntries = Object.entries(b);

  if (aEntries.length !== bEntries.length) return false;

  const bSet = new Set(bEntries.map(([key, value]) => `${key}:${value}`));

  return aEntries.every(([key, value]) => bSet.has(`${key}:${value}`));
};

export const useStoreFeatureCookie = ({ queryString }: { queryString: string }) => {
  useEffect(() => {
    const { FEATURE_TOGGLE, ft, ...rest } = parse(queryString, { ignoreQueryPrefix: true });
    const overrides = FEATURE_TOGGLE ?? ft;

    if (overrides === undefined || typeof overrides !== 'string') {
      return;
    }

    if (overrides === '' || overrides === 'false') {
      removeCookie('feature_toggles');
    } else {
      const newOverrides: { [key: string]: boolean } = {};
      overrides.split(',').forEach(override => {
        const [key, value] = override.split(':');
        newOverrides[key] = value !== 'false';
      });

      let currentOverrides = {};
      try {
        currentOverrides = JSON.parse(getCookie('feature_toggles') ?? '{}');
      } catch (error) {
        console.error(error);
      }

      if (!isOverridesEqual(currentOverrides, newOverrides)) {
        setCookie('feature_toggles', { ...currentOverrides, ...newOverrides });
      }

      window.location.search = stringify(rest);
    }
  }, [queryString]);

  return null;
};
