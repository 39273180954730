import { useEffect, useState } from 'react';

function getCookieByName(cookieName: string): string | null {
  const cookieToFind = `${cookieName}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieToFind)) {
      return decodeURIComponent(cookie.substring(cookieToFind.length));
    }
  }
  return null;
}

export const useCheckNewNavigationDisabled = (): boolean => {
  const [newNavigationDisabled, setNewNavigationDisabled] = useState(false);

  useEffect(() => {
    const rawCookie = getCookieByName('new_navigation_feature_disabled');

    if (rawCookie) {
      try {
        const { value, expiry } = JSON.parse(rawCookie);
        const expiryDate = new Date(expiry);

        if (expiryDate > new Date() && value === 'true') {
          setNewNavigationDisabled(true);
        } else {
          // If expired or invalid, remove the cookie
          document.cookie =
            'new_navigation_feature_disabled=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          setNewNavigationDisabled(false);
        }
      } catch (error) {
        console.error('Invalid cookie format:', error);
        setNewNavigationDisabled(false);
      }
    } else {
      setNewNavigationDisabled(false);
    }
  }, []);

  return newNavigationDisabled;
};
