export const breakpoints = {
  mobile: 384,
  phablet: 560,
  tablet: 720,
  desktop: 880,
  navigationBreakpoint: 1024,
  normal: 1200,
  giant: 1500,
} as const;

export const heightBreakpoints = {
  compactNavigation: 800,
} as const;

type BreakpointKey = keyof typeof breakpoints;

export function lessThan(key: BreakpointKey) {
  return `@media (max-width: ${breakpoints[key] - 1}px)`;
}

export function greaterThan(key: BreakpointKey) {
  return `@media (min-width: ${breakpoints[key]}px)`;
}

export function between(firstKey: BreakpointKey, secondKey: BreakpointKey) {
  return `@media (min-width: ${breakpoints[firstKey]}px) and (max-width: ${
    breakpoints[secondKey] - 1
  }px)`;
}

export function compactDesktopNavigation() {
  return `@media (max-height: ${heightBreakpoints.compactNavigation}px) and (min-width: ${breakpoints.navigationBreakpoint}px)`;
}

export const media = {
  lessThan,
  greaterThan,
  between,
  compactDesktopNavigation,
};

export default media;
