import type React from 'react';
import { useEffect, useRef } from 'react';

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: (e: MouseEvent) => void,
  isActive = true,
  customIgnore?: (
    el: HTMLElement,
  ) => boolean /* Custom eval to ignore the click outside callback for certain elements */,
) => {
  const onClickOutsideRef = useRef(onClickOutside);

  useEffect(() => {
    onClickOutsideRef.current = onClickOutside;
  });

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && e.target && ref.current.contains(e.target as Node)) {
        return;
      }
      if (customIgnore?.(e.target as HTMLElement)) return;

      onClickOutsideRef.current(e);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isActive, customIgnore]);
};
